<template>
  <div class="contents" id="contents">
    <section class="section wwd-section">
      <div class="section-wrap">
        <div class="wwd-wrap">
          <div class="text-area">
            <aside class="text-wrap">
              <h2 class="wwd-title">WHAT WE DO</h2>
              <div class="wwd-content-1">
                <p v-html="$t('content.main.wwd.txt01')">
                </p>
              </div>
              <div class="wwd-content-2">
                <p v-html="$t('content.main.wwd.txt02')">
                </p>
              </div>
            </aside>
          </div>
          <div class="image-area">
            <div class="wwd-bg"></div>
          </div>
        </div>
      </div>
    </section>
    <!-- // WHAT WE DO -->
    <!-- Open Innovation Platform -->
    <section class="section platform-section">
      <div class="section-wrap">
        <h3 class="platform-title">Open Innovation Platform</h3>
        <div class="title-description platform-description">{{$t('content.main.wwd.txt03')}}</div>
        <div class="diagram-wrap">
          <ul class="diagram-outer">
            <li class="circle-outer">
              <div class="circle-layer"></div>
              <div class="circle-layer-bottom"></div>
              <div class="diagram-inner">
                <ul class="diagram-small">
                  <li>
                    <span v-html="$t('content.main.wwd.txt04')"></span>
                  </li>
                  <li>
                    <span>{{$t('content.main.wwd.txt05')}}</span>
                  </li>
                  <li>
                    <span>{{$t('content.main.wwd.txt06')}}</span>
                  </li>
                  <li>
                    <span>{{$t('content.main.wwd.txt07')}}</span>
                  </li>
                  <li>
                    <span v-html="$t('content.main.wwd.txt08')">
                    </span>
                  </li>
                </ul>
              </div>
  <!--<svg width="100%" height="100%">-->
  <!-- <circle cx="50%" cy="50%" r="50%" stroke-width="1" stroke="rgba(218, 218, 218, .6)"
  fill="transparent"></circle>-->
  <!-- </svg>-->
            </li>
            <li class="circle-outer">
              <div class="circle-layer"></div>
              <div class="circle-layer-bottom"></div>
              <dl class="diagram-inner green">
                <dt>Open Innovation Ecosystem</dt>
                <dd v-html="$t('content.main.wwd.txt09')">
                </dd>
              </dl>
              <span class="diagram-next">next-arrow</span>
            </li>
            <li class="circle-outer">
              <div class="circle-layer"></div>
              <div class="circle-layer-bottom"></div>
              <dl class="diagram-inner blue">
                <dt>Business Enabler</dt>
                <dd v-html="$t('content.main.wwd.txt10')">
                </dd>
              </dl>
              <span class="diagram-next">next-arrow</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- // Open Innovation Platform -->
    <!-- Our Offerings -->
    <section class="section offerings-section">
      <div class="section-wrap">
        <h3 class="offerings-title">Offerings</h3>
        <StaggerTransition targets=".card-list li">
        <ul class="card-list">
          <li>
            <h4>
              DREAMPLUS
              <br />Centers
            </h4>
            <p v-html="$t('content.main.wwd.txt11')">
            </p>
          </li>
          <li>
            <h4>
              Accelerating
              <br />Programs
            </h4>
            <p v-html="$t('content.main.wwd.txt12')">
            </p>
          </li>
          <li>
            <h4>
              Networking
              <br />Open Partnership
            </h4>
            <p v-html="$t('content.main.wwd.txt13')">
            </p>
          </li>
          <li>
            <h4>
              Investment
              <br />& Collaboration
            </h4>
            <p v-html="$t('content.main.wwd.txt14')">
            </p>
          </li>
          <li>
            <h4>
              Events
              <br />& Academy
            </h4>
            <p v-html="$t('content.main.wwd.txt15')">
            </p>
          </li>
        </ul>
        </StaggerTransition>
      </div>
    </section>
    <!-- // Our Offerings -->
    <!-- DREAMPLUS HISTORY -->
    <section class="section history-section">
      <div class="section-wrap">
        <h3 class="history-title">DREAMPLUS HISTORY</h3>
        <div class="title-description history-description" v-html="$t('content.main.wwd.txt16')">
        </div>
        <div class="history-wrap">
          <div class="line-vertical">
            <span class="line-vertical-arrow"></span>
          </div>
          <ul>
            <li>
              <div class="history-item-wrap">
                <div class="history-item">
                  <h5 class="history-year">2014</h5>
                  <dl class="history-desc">
                    <dt>Pan-Asia Alliance</dt>
                    <dd v-html="$t('content.main.wwd.txt17')">
                    </dd>
                  </dl>
                  <div class="icon"></div>
                </div>
                <div class="line"></div>
              </div>
            </li>
            <li>
              <div class="history-item-wrap">
                <div class="history-item">
                  <h5 class="history-year">2016</h5>
                  <dl class="history-desc">
                    <dt>Accelerating</dt>
                    <dd v-html="$t('content.main.wwd.txt18')">
                    </dd>
                  </dl>
                  <div class="icon"></div>
                </div>
                <div class="line"></div>
              </div>
            </li>
            <li>
              <div class="history-item-wrap">
                <div class="history-item">
                  <h5 class="history-year">2018</h5>
                  <dl class="history-desc">
                    <dt>Co-Working</dt>
                    <dd v-html="$t('content.main.wwd.txt19')">
                    </dd>
                  </dl>
                  <div class="icon"></div>
                </div>
                <div class="line"></div>
              </div>
            </li>
            <li>
              <div class="history-item-wrap">
                <div class="history-item">
                  <h5 class="history-year">2018</h5>
                  <dl class="history-desc">
                    <dt>Academy</dt>
                    <dd v-html="$t('content.main.wwd.txt20')">
                    </dd>
                  </dl>
                  <div class="icon"></div>
                </div>
                <div class="line"></div>
              </div>
            </li>
            <li>
              <div class="history-item-wrap">
                <div class="history-item">
                  <h5 class="history-year">2018</h5>
                  <dl class="history-desc">
                    <dt>DREAMPLUS Portal</dt>
                    <dd v-html="$t('content.main.wwd.txt21')">
                    </dd>
                  </dl>
                  <div class="icon"></div>
                </div>
                <div class="line"></div>
              </div>
            </li>
            <li>
              <div class="history-item-wrap">
                <div class="history-item">
                  <h5 class="history-year">2019</h5>
                  <dl class="history-desc">
                    <dt>Global Expansion</dt>
                    <dd v-html="$t('content.main.wwd.txt22')">
                    </dd>
                  </dl>
                  <div class="icon"></div>
                </div>
                <div class="line"></div>
              </div>
            </li>
            <li>
              <div class="history-item-wrap">
                <div class="history-item">
                  <h5 class="history-year">2019</h5>
                  <dl class="history-desc">
                    <dt>Co-Living</dt>
                    <dd v-html="$t('content.main.wwd.txt23')">
                    </dd>
                  </dl>
                  <div class="icon"></div>
                </div>
                <div class="line"></div>
              </div>
            </li>
            <li>
              <div class="history-item-wrap">
                <div class="history-item">
                  <h5 class="history-year">2019</h5>
                  <dl class="history-desc">
                    <dt>DREAM PARK</dt>
                    <dd v-html="$t('content.main.wwd.txt24')">
                    </dd>
                  </dl>
                  <div class="icon"></div>
                </div>
                <div class="line"></div>
              </div>
            </li>
          </ul>
        </div>
        <div class="hero-message ani-text-up" data-delay="500">
          <blockquote>
            Creating shared value that combines
            <br />social value and business value.
          </blockquote>
        </div>
      </div>
    </section>
    <!-- // DREAMPLUS HISTORY -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils, mixinMotion } from '@/common/helperUtils';
import { gsap } from '@/common/motions';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';

export default {
  name: 'WhoWeAre',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    StaggerTransition,
  },
  data() {
    return {};
  },
  mounted() {
    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: '.wwd-section',
        // markers:true,
      },
    });
    tl1
      .fromTo(
        '.wwd-bg',
        5,
        { autoAlpha: 0, scale: 1.35, ease: 'Power4.easeOut' },
        { autoAlpha: 1, scale: 1, ease: 'Power4.easeOut' },
      )
      .fromTo(
        '.wwd-title',
        1,
        { autoAlpha: 0, y: 20 },
        { autoAlpha: 1, y: 0, ease: 'Back.easeOut' },
        '-=4.5',
      )
      .fromTo(
        '.wwd-content-1',
        1,
        { autoAlpha: 0, y: 20 },
        { autoAlpha: 1, y: 0, ease: 'Back.easeOut' },
        '-=3.5',
      )
      .fromTo(
        '.wwd-content-2',
        1,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, ease: 'Back.easeOut' },
        '-=2.5',
      );

    // Open Innovation Platform
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: '.platform-section',
        // markers:true,
      },
    });
    tl2
      .from('.platform-title', 1.4, {
        autoAlpha: 0,
        y: 20,
        ease: 'Back.easeOut',
      })
      .from(
        '.platform-description',
        1,
        { autoAlpha: 0, y: 20, ease: 'Back.easeOut' },
        '-=1',
      )
      .staggerFromTo(
        '.diagram-small > li',
        0.75,
        { autoAlpha: 0, scale: 0 },
        {
          autoAlpha: 1,
          scale: 1,
          stagger: 0.05,
          ease: 'Power4.easeNone',
        },
        '-=2.4',
      )
      .from(
        '.diagram-inner.green',
        0.75,
        { autoAlpha: 0, scale: 0.7, ease: 'Power4.easeNone' },
        '-=1',
      )
      .from(
        '.diagram-inner.blue',
        0.75,
        { autoAlpha: 0, scale: 0.7, ease: 'Power4.easeNone' },
        '-=1',
      )
      .from(
        '.circle-layer',
        0.75,
        { autoAlpha: 0, scale: 0, ease: 'Linear.easeNone' },
        '-=0.5',
      )
      .from(
        '.diagram-next',
        0.75,
        { autoAlpha: 0, y: 20, ease: 'Back.easeOut' },
        '-=0.3',
      );

    // Our Offerings
    // const tl3 = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: '.offerings-section',
    //   },
    // });
    // tl3
    //   .from('.offerings-title', 1, {
    //     autoAlpha: 0,
    //     y: 20,
    //     ease: 'Back.easeOut',
    //   })
    //   .staggerFromTo(
    //     '.card-list > li',
    //     0.66,
    //     { autoAlpha: 0, y: 50, scale: 0.9 },
    //     {
    //       autoAlpha: 1,
    //       y: 0,
    //       stagger: 0.11,
    //       scale: 1,
    //       ease: 'Back.easeOut',
    //     },
    //     '-=1',
    //   );

    // HISTORY
    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: '.history-section',
        // markers: true,
      },
    });
    tl4
      .from('.history-title', 1, { autoAlpha: 0, y: 20, ease: 'Back.easeOut' })
      .from(
        '.history-description',
        1,
        { autoAlpha: 0, y: 20, ease: 'Back.easeOut' },
        '-=0.5',
      )
      .fromTo(
        '.line-vertical-arrow',
        1,
        { autoAlpha: 0, y: -50 },
        { autoAlpha: 1, y: 0, ease: 'power4.inOut' },
        '-=1',
      )
      .fromTo(
        '.line-vertical',
        0.5,
        { autoAlpha: 1, height: '0' },
        { autoAlpha: 1, height: '100%', ease: 'power4.inOut' },
        '-=.2',
      )
      .staggerFromTo(
        '.history-item-wrap',
        0.88,
        { autoAlpha: 0, y: 70 },
        {
          autoAlpha: 1,
          y: 0,
          stagger: 0.11,
          ease: 'Back.easeOut',
        },
        '-=.1',
      );
  },
};
</script>
